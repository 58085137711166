<template>
  <div class="calender-wrapper">
    <b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Calendar </h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item active>Calendar</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
		</b-row>
		<br>
  <div class="calendar_topbar">
    <b-row>
      <b-col class="calender_topcol" cols="12" lg="3" md="6" sm="12">
        <div class="calendar_select">
          <multiselect
          v-model="vendor_selected"
          :options="vendor_option"
          placeholder="Select Vendor Name"
          track-by="name"
          label="name"
          @input="parentVenueList()"
          >
          </multiselect>
        </div>
      </b-col>
      <b-col class="calender_topcol" cols="12" lg="3" md="6" sm="12">
        <div class="calendar_select">
          <multiselect
          v-model="parent_selected"
          :options="parent_option"
          placeholder="Select Parent Venue"
          track-by="name"
          label="name"
          @input="callChildVenue()"
          >
          </multiselect>
        </div>
      </b-col>
      <b-col class="calender_topcol" cols="12" lg="3" md="6" sm="12">
        <div class="calendar_select">
          <multiselect
          v-model="child_selected"
          :options="child_option"
          placeholder="Select Child Venue"
          track-by="name"
          label="name"
          >
          </multiselect>
        </div>
      </b-col>
      <b-col class="calender_topcol" cols="12" lg="2" md="4" sm="12">
        <div class="calendar_select">
          <b-button class="btn btn-venuecolor " :disabled="child_selected==''" @click="get_calendar_validation('Nrl')"> <b-spinner small v-if="loader_appy" ></b-spinner> Apply</b-button>
        </div>
      </b-col>
      
    </b-row>
  
  
  <table class="table table-bordered table-wrappers-calender  main-calender" :class="table_responsive">
    <thead class="calender-header">
      <th colspan="2" style="border-right: hidden;">
        {{ nameofvaenue }}
      </th>
      <th colspan="3" class="text-center" style="border-right: hidden;">
        <div class="d-flex justify-content-center">
          <div>
            <i class="mdi mdi-chevron-left cursor-pointer fs-3"  @click="get_calendar_validation('Pre')"></i>
          </div>
          <div class="_Main_calender">
            <datepicker
            minimum-view="month"
            id="datePickerId"
            format="MMMM-yyyy"
            placeholder="Enter built year"
            v-model="year_month_val"
            class="form-control custom_date_picker extraline"
            @input="get_calendar_validation('Nrl')"
            :disabled-dates="disabledDates"
            
            >
            </datepicker> 
          </div>
          <div>
            <i class="mdi mdi-chevron-right cursor-pointer fs-3"  @click="get_calendar_validation('Nxt')"></i>
          </div>
          
        </div>
      </th>
      <th colspan="2" class="text-right" >
        <div @click="todaydateclick()" >
          <div >Today :</div>    <div class="cursor-pointer" >{{ today_date() }}</div> 
        </div>
      </th>
    </thead>
    <thead class="theadfixed">
      <tr>
        <th  v-for="(day,index) in days_header" :key="index"  class="text-left" :class="day.short">
          <span class="d-none d-sm-block " >{{ day.Fullname }}</span>
          <span class="d-block d-sm-none " >{{ day.short }}</span>
          
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row) in 6" :key="row" class="text-center ">
        <td  v-for="(day1,indexs) in days" :key="indexs" :class="day1" > 
          <div class="row">
            <div class="col-md-2 col-sm-1 col-lg-2 col-2">
              <span class="fsC-5 text-default " :class="{ [active_ela == checkcalender(results[day1][row-1]) ? 'activea' :'']:true, } " style="margin-top: -22%;">{{checkcalender(results[day1][row-1])}}</span> 
              
            </div>
            <div class="col-md-10 col-sm-10 col-md-10 col-lg-10 col-10 cursor-pointer">
              <table class="newborderless " v-if="checkcalender(results[day1][row-1])">
                <tr v-for="(et,t) in extra_time" :key="t">
                  <td  @click="selectview(checkcalender(results[day1][row-1]),extra_time)" :class="{[check_booking(job[checkcalender(results[day1][row-1])],et.Number,'color')]:true,[day1]:true,'text-left':true,'border-b-1':true}" >
                    <span  v-if="custom_amount[checkcalender(results[day1][row-1])] && custom_amount[checkcalender(results[day1][row-1])][month][year][et.Number]">
                      <span v-if="check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status')" class="mt-1 " > 
                        
                        {{ check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status') }}
                        <span v-if="check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status')=='Blocked'" > 
                          <i class="mdi mdi-clock-in"></i> &nbsp;  &nbsp;  &nbsp;  &nbsp; 
                        </span>
                        <span v-else>
                          <i class="mdi mdi-check"></i> &nbsp;  &nbsp;  &nbsp;  &nbsp;
                        </span>
                      </span>
                      <span class="" v-else>
                        {{changing_sentence(et.Short) }}  <span class="float-right ml-1">
                        <span v-html="currency_check(formatPrice(custom_amount[checkcalender(results[day1][row-1])][month][year][et.Number][0]))"></span></span>
                        <!-- <i class="mdi mdi-currency-inr"></i>{{ formatPrice(custom_amount[checkcalender(results[day1][row-1])][month][year][et.Number][0]) }}</span> -->
                        
                      </span>
                      
                    </span>
                    <span v-else>
                      <br>
                    </span> 
                  </td>
                </tr>
              </table>
            </div>
          </div>
          
          <!-- 
            <div class="row">
            <div class="col-md-2 col-sm-1 col-lg-3 col-2">
            <span class="fsC-5 text-default " :class="{ activea : active_ela == checkcalender(results[day1][row-1]) } ">{{checkcalender(results[day1][row-1])}}</span> 
            
            </div>
            <div class="col-md-10 col-sm-10 col-md-10 col-lg-10 col-10">
            <span v-if="checkcalender(results[day1][row-1])" >
            <ul class="different-calendar" :class="fs_custom" @click="selectview(checkcalender(results[day1][row-1]),extra_time)">
            <li v-for="(et,t) in extra_time" :key="t" :class="{[check_booking(job[checkcalender(results[day1][row-1])],et.Number,'color')]:true,[day1]:true}">
            <span  v-if="custom_amount[checkcalender(results[day1][row-1])] && custom_amount[checkcalender(results[day1][row-1])][month][year][et.Number]">
                          <span v-if="check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status')">
            
                          {{ check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status') }}
                          <span v-if="check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status')=='Blocked'"> 
            <i class="mdi mdi-clock-in"></i>
            </span>
                          <span v-else>
            <i class="mdi mdi-check"></i>
                          </span>
            </span>
            <span class="normaldgt" v-else>
                          {{et.Short }}<i class="mdi mdi-arrow-right"></i>  <i class="mdi mdi-currency-inr"></i>{{ formatPrice(custom_amount[checkcalender(results[day1][row-1])][month][year][et.Number][0]) }}
            
            </span>
            
            </span>
            <span v-else>
            
            </span> 
            
            </li>
            </ul>     
            </span>
            </div>
          </div> -->
          
          
          <!-- <span class="date">
            <span class="fsC-5 text-default " :class="{ activea : active_ela == checkcalender(results[day1][row-1]) } ">{{checkcalender(results[day1][row-1])}}</span> 
            <span v-if="checkcalender(results[day1][row-1])" >
            
            
            
            <ul class="different-calendar" :class="fs_custom" @click="selectview(checkcalender(results[day1][row-1]),extra_time)">
            <li v-for="(et,t) in extra_time" :key="t" :class="{[check_booking(job[checkcalender(results[day1][row-1])],et.Number,'color')]:true,[day1]:true}">
            <span  v-if="custom_amount[checkcalender(results[day1][row-1])] && custom_amount[checkcalender(results[day1][row-1])][month][year][et.Number]">
                          <span v-if="check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status')">
            
                          {{ check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status') }}
                          <span v-if="check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status')=='Blocked'"> 
            <i class="mdi mdi-clock-in"></i>
            </span>
                          <span v-else>
            <i class="mdi mdi-check"></i>
                          </span>
            </span>
            <span class="normaldgt" v-else>
                          {{et.Short }}<i class="mdi mdi-arrow-right"></i>  <i class="mdi mdi-currency-inr"></i>{{ formatPrice(custom_amount[checkcalender(results[day1][row-1])][month][year][et.Number][0]) }}
            
            </span>
            
            </span>
            <span v-else>
            
            </span> 
            
            </li>
            
            </ul>  
            </span>
          </span> -->
          <!--	<div class="not-editable d-flex justify-content-start minus-top-mt-36" >
            <div class="" >
            <span class="fsC-5 text-muted" :class="{ activea : active_ela == checkcalender(results[day1][row-1]) } ">{{checkcalender(results[day1][row-1])}}</span> 
            </div>
            <div class="mt-2" v-if="checkcalender(results[day1][row-1])" >
            <ul class="top_class_list" :class="fs_custom" @click="selectview(checkcalender(results[day1][row-1]),extra_time)">
            
            <li v-for="(et,t) in extra_time" :key="t" :class="{[check_booking(job[checkcalender(results[day1][row-1])],et.Number,'color')]:true,[day1]:true}"  >
            
            <span  v-if="custom_amount[checkcalender(results[day1][row-1])] && custom_amount[checkcalender(results[day1][row-1])][month][year][et.Number]">
                          <span v-if="check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status')">
            
                          {{ check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status') }}
                          <span v-if="check_booking(job[checkcalender(results[day1][row-1])],et.Number,'status')=='Blocked'"> 
            <i class="mdi mdi-clock-in"></i>
            </span>
                          <span v-else>
            <i class="mdi mdi-check"></i>
                          </span>
            </span>
            <span v-else>
                          <div class="d-flex ">
            <div>
            {{et.Short }}<i class="mdi mdi-arrow-right"></i>
            </div>
            <div>
            <i class="mdi mdi-currency-inr"></i>{{ formatPrice(custom_amount[checkcalender(results[day1][row-1])][month][year][et.Number][0]) }}
            
            </div>
                          </div>
            </span>
            
            </span>
            <span v-else>
            
            </span> 
            </li> 
            
            
            
            
            </ul>
            </div>
            
            
          </div>-->
          
        </td>
      </tr>
    </tbody>
  </table>
  
  
  
  <!-- Sidebar click on Calender amount -->
  <b-sidebar no-header id="sidebar-right" right shadow ref="mySidebar" :visible="showSidebar">
    <div class="d-flex justify-content-between border-bottom-3 border-info p-3 mb-2 bg-primary text-white bg-gradient">
      <div >
        {{ selected_date_active }} 
      </div>
      <div >
        <div @click="OnClose()" class="cursor-pointer"><i class="mdi mdi-window-close"></i></div>
      </div> 
    </div>
    <div class="px-3 py-2 ">
      
      <div v-if="Inactive_date" >  
        <div v-if='seen'>
          <div >
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" v-model="checkedGroupVModel">
              <label class="form-check-label" for="exampleRadios1">
                Selected date Amount
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" v-model="checkedGroupVModel" @change="custom_amount_update()">
              <label class="form-check-label" for="exampleRadios2">
                Custom month
                
              </label>
            </div>
            <div v-if="checkedGroupVModel=='option1'">
              <div class="input-group mb-3">
                
                <input type="text" v-model="uAmount" class="form-control venue-custom-input" aria-label="Amount (to the nearest Rupees)">
                
                <button class="btn btn-venuecolor" type="button" @click="savetheamount()">
                  
                  <div class="spinner-border spinner-border-sm text-light" v-if="loader" role="status">
                  </div> Update</button>
              </div>
            </div>
            <!-- <div v-else-if="checkedGroupVModel=='option2'">
            venues/edit-child-venue/ -->
            <!--	<div class="row mb-3">
              <div class="col-6">
              <label for="inputEmail4" class="form-label">From Date</label>
              <input type="date" class="form-control" id="inputEmail4" v-model="value1"
              :min='disabledAfterToday()' >
              </div>
              <div class="col-6">
              <label for="inputEmail4" class="form-label">To date</label>
              <input type="date" class="form-control" id="inputEmail4" v-model="value2"
              :default-value="new Date()"
              :min='dateformate(value1)'>
              </div>
              </div>
              
              <div class="input-group mb-3">
              <span class="input-group-text">Rs</span>
              <input type="text" class="form-control" v-model="allAmount"  aria-label="Amount (to the nearest Rupees)">
              <span class="input-group-text">.00</span>
              <button class="btn btn-success" type="button" @click="saveAlltheamount()">
              
              <div class="spinner-border spinner-border-sm text-light" v-if="loader1" role="status">
              
              </div> Update</button>
            </div>-->
            <!--  </div> -->
            
          </div>
        </div>
        <div v-if='!seen' class="mb-2">
          <div v-if="statusM==undefined">
            <div class="row ml-2">
              <div class="d-flex justify-content-evenly">
                <div>
                  <button type="button" class="btn btn-outline-danger fs-10" size="sm"  @click="createOfflineBooking()">BOOK</button>	
                </div>
                <div>
                  <button type="button" class="btn btn-outline-success fs-10 ml-2" size="sm"  @click="amountCheck()">AMOUNT UPDATE</button>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="statusM==0">
            Please Click below 
          </div>
          <div v-else-if="statusM=='Booked'">
            Slot Status is : <span class="badge bg-info text-dark"> {{ statusM }} </span>
          </div> 
          <div v-else-if="statusM=='Blocked'">
            <div class="row ml-2">
              <div class="d-flex justify-content-evenly">
                <div>
                  <button type="button" class="btn btn-outline-danger fs-12" size="sm"  @click="EditOfflineBooking()">EDIT BLOCK</button>
                </div>
                <div>
                  <button type="button" class="btn btn-outline-success fs-12 ml-2" size="sm"  @click="amountCheck()">AMOUNT UPDATE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-list-group  v-for="(ts, t) in extra_time" :key="t" :class="{ activeated : active_el == ts.Number ,[checkAmount_slot(slots,month,year,ts.Number)==0 ? 'disabledClass' : ''] :true } "  class="mb-2 sm listgroup-custom" 
        @click="editSlot(ts.from_time,ts.to_time,ts.Number,checkAmount_slot(slots,month,year,ts.Number),check_booking(job[slots],ts.Number,'status'),check_booking(job[slots],ts.Number,'book_id'))" >
          
          <b-list-group-item v-bind:class="[checkAmount_slot(slots,month,year,ts.Number)==0 ? 'disabledClass' : 'active']"   class="fs-12">{{ ts.nameHead }} ({{ convert_24_12hour(ts.from_time) }} {{ convert_24_12hour(ts.to_time) }})</b-list-group-item>
          
          <b-list-group-item    class="fs-12" :class="{'list-group-item':true,[check_booking(job[slots],ts.Number,'color')]:true}">
            <span v-if="check_booking(job[slots],ts.Number,'status')">
              {{ check_booking(job[slots],ts.Number,'status')}} 
              
              <span v-if="check_booking(job[slots],ts.Number,'block')" style="float: right;"> 
                <i class="mdi mdi-clock-in"></i> <span class="d-none">{{ timerCount }} </span>
                {{lets_start_time(check_booking(job[slots],ts.Number,'book_date'),check_booking(job[slots],ts.Number,'block')) }}
              </span> 
              
            </span>
            <span v-else>
              Price  <!-- <i class="mdi mdi-currency-inr"></i> -->
              <span v-html="currency_check(formatPrice(checkAmount_slot(slots,month,year,ts.Number)))"></span>
              <!-- 	{{  formatPrice(checkAmount_slot(slots,month,year,ts.Number)) }} -->
            </span> 
            
          </b-list-group-item>
          
        </b-list-group>
        
      </div>
      <div v-else>
        <img src="https://media.tenor.com/9nBkUdr0goMAAAAi/shsh-emoji.gif" class="w-25">
        <h3>Sorry This Date is not Avaliable </h3>
      </div>  
    </div>
  </b-sidebar> 
  <!-- Sidebar click on Calender amount -->
</div>
</div>

</template>


<script>

import axios from "axios";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
  import { mapState } from "vuex";
export default {
  components: {
    Datepicker,
    Multiselect
  },
  data(){
    return {
    parent_id:"Select Parent venue",
    child_id:"",
    parent_option:[],
    child_option:[],
    vendor_selected: "",
    vendor_option: [],
    parent_selected: "",
    child_selected: "",
    days_header:[],
    days:[],
    year_month_val:new Date(),
    results:[],
    showSidebar:false,
    table_responsive:'w-100',
    fs_custom:'font-custom-14',
    selected_date_active:"",
    Inactive_date:false,
    seen:false,
    active_ela:100,
    statusM:0,
    month:'',
    year:'',
    job:[],
    custom_amount:[],
    active_el:"",
    checkedGroupVModel:"option1",
    loader:false,
    loader1:false,
    countDownToTime : new Date("Sep 26, 2029 00:00:00").getTime(),
    timerCount: 30,
    type:"Nrl",
    disabledDates: {
      to: new Date(Date.now() - 8640000)
    },
    loader_appy:false,
    uAmount:0,
    shift_type:"",
    from_time:"00:00",
    to_time:"00:00",
    monthYears:'',
    nameofvaenue:'',
    slot_book_id:'',
    curncy:'',
}
},
methods:
{
/* parentVenueList:function() {
  axios.get("/api/parent_venue_listing").then((resp) => {
  if (resp.data.status_code == 200) {
  
  this.parent_selected=resp.data.parent_venue_details;
  
  }
  });
}, */
/*  callChildVenue:function()
  {
  var child_id = this.parent_selected;
  axios
  .post("/api/child_venue_listing", {
  parent_venue_id: child_id,
  })
  .then((resp) => {
  if (resp.data.status_code == 200) {
  
  this.child_selected=resp.data.child_venue_details;
  
  }
  });
}, */
callChildVenue() {
  if (this.parent_selected === "" || this.parent_selected === null) {
    this.child_option = [];
    } else {
    var child_id = this.parent_selected.value;
    axios
    .post("/admin/child_venue_listing", {
      parent_venue_id: child_id,
    })
    .then((resp) => {
      if (resp.data.status_code == 200) {
        if (resp.data.status) {
          this.child_option = [];
          this.child_selected = "";
          for (var i = 0; i < resp.data.child_venue_details.length; i++) {
            this.child_option.push({
              value: resp.data.child_venue_details[i].child_venue_id,
              name: resp.data.child_venue_details[i].child_venue_name,
            });
          }
          } else {
          this.child_option = [];
        }
      }
    });
  }
},
next_calender_load(type)
{
  this.monthYears= this.opendateformate(this.year_month_val);
  this.get_calendar_validation(type)
},
todaydateclick()
{
  this.year_month_val=new Date();
  this.get_calendar_validation('Nrl')
},

get_calender_load()
{
  
  axios
  .post("/admin/load__calender", {
    month_year: this.year_month_val,
    type: this.type,
  })
  .then((resp) => {
    // if (resp.data.status_code == 200) {
    this.days=resp.data.days;
    this.days_header=resp.data.days_header;
    this.month=resp.data.month;
    this.year=resp.data.year;
    this.results = resp.data.calender;
    this.year_month_val = resp.data.Selected_date;
    this.extra_time=[];
    this.monthYears= this.opendateformate(this.year_month_val);
  })
  
},
get_calendar_validation(type)
{
  
  this.loader_appy=true;
  if (this.child_selected !== "" && this.parent_selected !== "")  {
    axios
    .post("/admin/load_new_calender", {
      parent_venue_id: this.parent_selected,
      child_venue_id: this.child_selected.value,
      month_year: this.year_month_val,
      type : type
    })
    .then((resp) => {
      // if (resp.data.status_code == 200) {
      this.loader_appy=false;
      this.nameofvaenue=this.child_selected.name;
      this.days=resp.data.days;
      this.days_header=resp.data.days_header;
      this.month=resp.data.month;
      this.year=resp.data.year;
      
      this.results = resp.data.calender;
      this.job = resp.data.job;
      var cust_amt =resp.data.custom_amount;
      this.year_month_val = resp.data.Selected_date;
      if(cust_amt){
        
        this.custom_amount =cust_amt;
        this.extra_time=resp.data.extra_time;
      }
      else
      {
        this.$root.$refs.app.showToast("Sorry! No data Found");
        this.extra_time=[];
      }
      this.monthYears= this.opendateformate(this.year_month_val);
      // }
    });
  }
  else
  {
    this.$root.$refs.app.showToast("danger", "Please select venue");
  }
},
checkcalender(date)
{
  return (date ? date['month_days'] : "");
},
selectview:function(day)
{
  var monthNames = [ "January", "February", "March", "April", "May", "June", 
  "July", "August", "September", "October", "November", "December" ];
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
  this.showSidebar = true // Open modal
  this.slots=day; 
  this.active_ela=day; 
  //this.table_responsive='w-85';// responsive css
  //this.fs_custom='font-custom-9';// responsive css
  this.active_el=""; //day select only for Css
  
  //const selectedDate =this.dateformate(this.year_month_val);
  var mon=this.month.toString().padStart(2, "0");
  
  var selectedDate= [this.year, mon, day].join('-');
  
  const today= this.disabledAfterToday();
  if(selectedDate>=today)
  {
    this.Inactive_date=true
  }
  else
  {
    this.Inactive_date=false;
  }
  var d = new Date(selectedDate);
  var dayName = days[d.getDay()];
  this.selected_date_active=dayName+', '+day+'th '  + monthNames[this.month-1] + ' '+ this.year;
  this.selected_date_new=this.year+'-'  + this.month + '-'+ day;
  this.seen=false;
  this.statusM=0;
  
},
OnClose:function()
{
  this.table_responsive='w-100';
  this.showSidebar = false;
  this.fs_custom='font-custom-14';
  this.active_ela=100;
},

/*  Date functions */
disabledAfterToday:function() {
  var d = new Date(),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();
  
  if (month.length < 2) 
  month = '0' + month;
  if (day.length < 2) 
  day = '0' + day;
  
  return [year, month, day].join('-');
},
dateformate:function(date)
{
  var myDate = new Date(new Date(date).getTime()+(1*24*60*60*1000));
  var d = new Date(myDate),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();
  
  if (month.length < 2) 
  month = '0' + month;
  if (day.length < 2) 
  day = '0' + day;
  
  return [year, month, day].join('-');
},
opendateformate:function(date) 
{
  var myDate = new Date(new Date(date).getTime()+(1*24*60*60*1000));
  var d = new Date(myDate),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate();
  // year = d.getFullYear();
  
  if (month.length < 2) 
  month = '0' + month;
  if (day.length < 2) 
  day = '0' + day;
  var monthNames = [ "January", "February", "March", "April", "May", "June", 
  "July", "August", "September", "October", "November", "December" ];
  
  return monthNames[month-1] ;
},
todaydate:function()
{
  var d = new Date(),
  day = d.getDate();
  
  if (day.length < 2) 
  day = '0' + day;
  
  return day;
},
check_booking(booking_data,type,chck)
{
  var d=booking_data ? booking_data[type]: "";
  if(d!=undefined && d!="")
  {
    if(chck=='status')
    {
      return d[0]['status'];
    }
    else if(chck=='color')
    {
      return d[0]['color'];
    }
    else if(chck=='block')
    {
      return d[0]['blockeddate'];
    } 
    else if(chck=='book_date')
    {
      return d[0]['totalblockhour'];
    } 
    else if(chck=='book_id')
    {
      return d[0]['book_id'];
    } 
    
  }
  
},
checkAmount_slot:function(slots,month,year,short)
{
  if (this.custom_amount[slots] && this.custom_amount[slots][month][year][short])
  {
    return this.custom_amount[slots][month][year][short][0];
  }
  else
  {
    return 0;
  }        
},
convert_24_12hour(timeString)
{
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
},
editSlot:function(fromtime,totime,shrt,amount,slot,book_id)
{
  this.from_time=fromtime;
  this.to_time=totime;
  // this.statusM=status;
  this.statusM=slot;
  
  this.amount=amount;
  this.uAmount=amount;
  this.active_el=shrt;
  this.shift_type=shrt;
  this.afterClick=true;
  // $('#exampleModal').modal('show');
  this.seen=false;
  this.slot_book_id=book_id;
  this.disableClass="aaaaaaa";
},
amountCheck:function()
{
  this.seen=true;
},
savetheamount:function()
{
  this.loader=true;
  axios.post('/admin/all_amount_is_update', 
  {amount: this.uAmount,shift: this.shift_type,vid: this.child_selected.value,fdate: this.selected_date_new,tdate: this.selected_date_new}).then(response => {
    response;
    
    this.loader=false;
    this.get_calendar_validation('Nrl')
  });
},
saveAlltheamount:function()
{
  this.loader1=true;
  this.$http.post('http://localhost:8000/all_amount_is_update', 
  {amount: this.allAmount,shift: this.slot_amount_id,venue: this.venue,fdate: this.value1,tdate:this.value2}).then(response => {
    response;
    this.searchfilter();
    this.loader1=false;
    this.$toaster.success('Your Amount has been Updated.', {timeout: 8000})
    //toastr.success("New Job Created");
    //this.notificationcheck();
  });
}, 
startTimer() {
  const blockedTime = new Date().getTime();
  const blockTime = new Date(this.countDownToTime).getTime();
  const timeDifference = blockTime - blockedTime;
  const millisecondsInOneSecond = 1000;
  const millisecondsInOneMinute = millisecondsInOneSecond * 60;
  const millisecondsInOneHour = millisecondsInOneMinute * 60;
  const millisecondsInOneDay = millisecondsInOneHour * 24;
  const remainderDifferenceInHours = (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
  const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
  const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
  const remainingHours = Math.floor(remainderDifferenceInHours);
  const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
  const remainingSeconds =Math.floor(remainderDifferenceInSeconds);
  this.timerCount=remainingHours +":"+ remainingMinutes +":"+ remainingSeconds;
},
lets_start_time:function(todatetime,fromdate)
{
  console.log(fromdate);
  const blockedTime = new Date().getTime();
  const blockTime = new Date(todatetime).getTime();
  const timeDifference = blockTime - blockedTime;
  const millisecondsInOneSecond = 1000;
  const millisecondsInOneMinute = millisecondsInOneSecond * 60;
  const millisecondsInOneHour = millisecondsInOneMinute * 60;
  const millisecondsInOneDay = millisecondsInOneHour * 24;
  const differenceInDays = timeDifference / millisecondsInOneDay;
  const remainderDifferenceInHours = (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
  const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
  const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
  const remainingDays = Math.floor(differenceInDays);
  const remainingHours = Math.floor(remainderDifferenceInHours);
  const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
  const remainingSeconds =Math.floor(remainderDifferenceInSeconds);
  
  var timerCounter=0;
  if(remainingDays!=0)
  {
    var thr=remainingDays*24;
    var timg = parseInt(remainingHours)+parseInt(thr);
    timerCounter=timg +":"+ remainingMinutes +":"+ remainingSeconds;
  }
  else
  {
    timerCounter=remainingHours +":"+ remainingMinutes +":"+ remainingSeconds;
  }
  if(remainingMinutes==0)
  {
    //this.$toaster.warning('Blocking time one hour left .', {timeout: 2000});
    
  }
  else if(remainingSeconds==0)
  {
    //alert("time over")
    //this.$toaster.warning('Blocking time one min left .', {timeout: 2000});
  }
  if(timerCounter<="0:0:0")
  {
    this.blocktimeUpdate();
  }
  
  return timerCounter;
},
createOfflineBooking(){
  
  var offline_calendar_arr = [{
    childvenue_id:this.child_selected.value,
    shift_date:Number(this.slots).toString(),
    time_shift_type:this.active_el,
    from_time:this.from_time,
    to_time:this.to_time,
    month: Number(this.month).toString(),
    year: this.year,
  }];
  this.$store.commit("SET_OFFLINE_CHILDVENUE", offline_calendar_arr);
  this.$router.push("/add-booking");
},
EditOfflineBooking()
{
  this.$router.push("/edit-booking/"+this.slot_book_id);
},
formatPrice(value) {
  //let val = (value/1).toFixed(2).replace(',', '.')
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
},
today_date()
{
  var myDate = new Date();
  var d = new Date(myDate),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();
  
  if (month.length < 2) 
  month = '0' + month;
  if (day.length < 2) 
  day = '0' + day;
  return [day,month,year].join('-');
},
custom_amount_update()
{
  this.$store.commit("SET_CUSTOM_AMOUNT_CHANGE", '2');
  this.$router.push("/venues/new_edit_child_venue/index/"+this.child_selected.value);
},
changing_sentence(shift_name)
{
  if(shift_name=="M")
  {
    return "Morning";
  }
  else if(shift_name=="E")
  {
    return "Evening";
  }
  else if(shift_name=="A")
  {
    return "Noon";
  }
},
  vendorListing() {
    axios.post("/admin/get_all_vendors_list").then((resp) => {
      if (resp.data.status_code == 200) {
        if (resp.data.status) {
          for (var i = 0; i < resp.data.vendors.length; i++) {
            this.vendor_option.push({
              value: resp.data.vendors[i].user_id,
              name:
                resp.data.vendors[i].first_name +
                " " +
                resp.data.vendors[i].last_name,
            });
          }
        }
      }
    });
  },
parentVenueList() {
      if (this.vendor_selected === "" || this.vendor_selected === null) {

      this.parent_option = [];
      this.child_option = [];
      this.parent_selected = "";
      this.child_selected = "";
    } else {
      this.parent_option = [];
      this.child_option = [];
      this.parent_selected = "";
      this.child_selected = "";
      var vendor_id = this.vendor_selected.value;//SET_ADMIN_ID
      this.$store.commit("SET_ADMIN_ID", this.vendor_selected);
 
      axios
        .post("/admin/get_vendors_parent_venues_list", {
          vendor_id: vendor_id,
        })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              for (
                var i = 0;
                i < resp.data.parent_venue_details.length;
                i++
              ) {
                this.parent_option.push({
                  value: resp.data.parent_venue_details[i].parent_venue_id,
                  name: resp.data.parent_venue_details[i].venue_name,
                });
              }
            }
          }
        });
    }
},
currency_check(amt) {
  return '<i class="mdi '+this.curncy+'"></i>'+amt;
},
getProfile() {
  axios.get("/admin/get_profile").then((resp) => {
    if (resp.data.status_code == 200) {
      this.curncy= resp.data.user_details.currencys;
    }
  });
},
blocktimeUpdate() {
  axios.get("/admin/autocallCalender").then((resp) => {
    resp
  });
},
},
mounted() 
{
  this.vendor_selected=this.set_admin_id;
this.vendorListing();
this.parentVenueList();
this.get_calender_load();
this.getProfile();
//this.getCurrnetYear();
setInterval(() => { this.startTimer() }, 1000);
},
watch: {

timerCount: {
  handler(value) {
    
    if (value > 0) {
      setTimeout(() => {
        this.lets_start_time();
      }, 1000);
    }
  },
  immediate: true // This ensures the watcher is triggered upon creation
}

},
computed: {
  ...mapState(["set_admin_id"]),
}
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
._Main_calender .vdp-datepicker {
width: 160px;
}
.calendar_selects .vdp-datepicker input {
  border: none !important;
}
.table-wrappers-calender  td {
padding: 6px 5px 10px;
}
.newborderless td{

padding: 5px;
}
.top_class_list li
{
list-style-type: none;
border-bottom: 1px solid #a3a5b1;
/* border: 1px dashed #b1b8ed; */
color: #170420;
cursor: pointer;
position: relative;
}
.fsC-5
{
font-size: 15px;
}
.table-wrappers-calender  thead th
{
/* background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%); */
color: white; 
}
#sidebar-right
{
top:10% !important;
height:unset !important;
width: 260px !important;
}
.w-85
{
width:85%;
font-size: 12px;
}
.font-custom-12
{
font-size: 10px;
}
.font-custom-9
{
font-size: 10px;
}
.table-wrappers-calender  td.Sun, td.Sat
{
background-color: #f5f3ff;
color: rgb(8, 8, 8);
}
.activea{
border: 1px solid red;
border-radius: 50px;
}
.fs-13
{
font-size: 13px;
}
.fs-12
{
font-size: 12px;
}
.fs-10
{
font-size: 10px;
}

.list-group-zoom
{
cursor: pointer;
}
.button-book
{
display: none;
}
div.card-body.list-group-item:hover > .button-book {
display: block;
}
.custom-list-group
{
--bs-list-group-item-padding-x: 5px;
--bs-list-group-item-padding-y: 3px;
}
.list-group-zoom:hover
{
transform: scale(1.02);
}
.listgroup-custom
{
cursor: pointer;
}
.listgroup-custom:hover
{
background-color:rgb(86, 96, 155);
transform: scale(1.02);
}
.listgroup-custom:active 
{
background-color:rgb(86, 96, 155);
transform: scale(1.02);
border: 2px solid #6610f2;
}
.activeated
{
border: 2px solid #6610f2;
  border-radius: 10px;
}
#datePickerId
{
border: none;
  background: unset;
  color: white;
  font-size: 19px;
  margin-top: -6px;
  margin-left: -6px;
  font-weight: 700;
}
.calender-header
{
background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%);
  margin-bottom: -4px;
  padding: 10px;
  color: white;
}
.theadfixed { 
background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%);
}
.list-group-item.active
{
background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%) !important;
}
.fs-14
{
font-size:14px;
}


.date {
text-align: left;
  display: block;
  height: 0;
  font-size: 0.7em;
  padding: 0.25em;
  padding-bottom: 65%;
  position: relative;
  margin-left: 0.25em;
  margin-right: -0.55em;
}
.date ul,
.date li {
margin: 0;
padding: 0;
list-style: none;
color: #333;
}
.date ul {
  text-align: center;
  font-size: 1.4em;
  width: 100%;
  overflow: hidden;
  position: absolute;
  font-weight: normal;
}
.date li {
color: #222;
width: 100%;
height: 1.4em;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
position: relative;
}
.date li:before {

color: inherit;
display: inline-block;
padding-right: 0.25em;
}
.time {
float: right;
text-align: right;
color: #999;
}
.event {
color: #333;
}
.normaldgt{
margin-left: -8px;
}
.extraline
{
  border: none;
  margin-top: 1%;
}

.different-calendar li
{
list-style-type: none;
}

</style>		